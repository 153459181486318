export const scripterServiceBaseUrl = 'https://api.scripter.warnerbros.com';

export const scripterServiceWSBaseUrl = 'wss://tyr6n2xzk9.execute-api.us-west-2.amazonaws.com/prod';  

export const scripterSharedServiceBaseUrl = '';

export const OKTA_CLIENT_ID = "0oaw78fh1ioWy1qKQ0x7";

export const OKTA_ISSUER_URL = "https://sso.wbd.com/oauth2/default";

export const OKTA_REDIRECT_URL = window.location.origin + '/callback';

export const OKTA_URL = "https://sso.wbd.com";

export const IDLE_TIMEOUT = "60";

export const IDLE_WARN_TIMEOUT = "30";